import React from 'react'
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { useNavigate } from 'react-router-dom';

const Interest = () => {
    const navigate = useNavigate()
    const particlesInit = async (main) => {
    
        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(main);
      };
    
      const particlesLoaded = (container) => {
      };
  return (
      <div onClick={() => navigate('/profil')} title='Magalie Dupont'>
        <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
            style: {
                width: "100%",
                height: "100%",
                position: 'initial'
            },
        background: {
            color: {
            value: "#2f3640",
            },
        },
        fpsLimit: 120,
        interactivity: {
            events: {
            onClick: {
                enable: true,
                
            },
            resize: false,
            },
        },
        particles: {
            color: {
                value: "#27ae60",
            },
            collisions: {
            enable: true,
            },
            move: {
            direction: "none",
            enable: true,
            outModes: {
                default: "bounce",
            },
            random: false,
            speed: 6,
            straight: false,
            },
            number: {
            density: {
                enable: true,
                area: 800,
            },
            value: 25,
            },
            opacity: {
            value: 0.7,
            },
            shape: {
            type: "circle",
            },
            size: {
            value: { min: 10, max: 40 },
            },
        },
        detectRetina: true,
        }}
        />
    </div>
  )
}

export default Interest