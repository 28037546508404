import React, { useEffect, useState } from 'react'
import Globe from 'react-globe.gl';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate()
    const [gData, setGData] = useState([])
    const [modalOpen, setModalOpen] = useState(false)

    useEffect(() => {
        const gData = [...Array(300).keys()].map(() => ({
            lat: (Math.random() - 0.5) * 180,
            lng: (Math.random() - 0.5) * 360,
            // size: Math.random() / 3,
            size: 0.02,
            name: "Magalie Dupont",
            color: ['red', 'white', 'blue', 'green'][Math.round(Math.random() * 3)]
          }));
          setGData(gData)
    }, [])

  return (
      <>
        <Globe
            // globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
            // globeImageUrl='./humans.png'
            globeImageUrl='./earth-large.jpg'
            // showGlobe={false}
            // backgroundColor="rgba(0,0,0,0)"
            width={1800}
            pointsData={gData}
            pointAltitude="size"
            pointColor="color"
            // onPointClick={(val) => navigate('/profil')}
            onPointClick={() => setModalOpen(!modalOpen)}
            showAtmosphere={true}
            atmosphereColor="red"
        />
        {
          modalOpen ?
            <div className='modal'>
              <span className="close" onClick={() => setModalOpen(false)}>X</span>
              <div className="profil" style={styles.profil}>
                <h1>MAGALIE DUPONT</h1>
                <img style={{width: 300, height: 300, margin: "auto", borderRadius: "50%"}} src="https://www.mariderm.com/wp-content/uploads/2018/12/visage-tonus-after.jpg" alt="" />
                <h3>Fonction: Directrice des Ressources Humaines</h3>
                <h3>Emplacement: CNES TOULOUSE</h3>
                <h3>MAIL: magalie.dupont@cnesmail.com</h3>
                <h3>TEL: 06.05.06.05.06</h3>
            </div>
            </div>
          : <></>
        }
    </>
  )
}

export default Home

const styles = {
  profil: {
      width: "100%",
      margin: 'auto',
      minHeight: '30vh',
      marginTop: 50
  }
}