import React from 'react'
import { Tree, TreeNode } from 'react-organizational-chart';
import { useNavigate } from 'react-router-dom';

const Organigramme = () => {
    const navigate = useNavigate()
    const styles = {
        padding: "5px",
        borderRadius: "8px",
        display: "inline-block",
        border: "1px solid red"
    }

  return (
      <div style={{marginTop: 50}}>
        <Tree
        lineWidth={'2px'}
        lineColor={'green'}
        lineBorderRadius={'10px'}
        label={<div style={styles} onClick={() => navigate('/profil')}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <img style={{width: 100, margin: "auto"}} src="https://www.mariderm.com/wp-content/uploads/2018/12/visage-tonus-after.jpg" alt="" />
                    <span>MAGALIE DUPONT</span>
                    <span>DRH</span>
                </div>
            </div>}
    >
        <TreeNode label={<div style={styles}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <img style={{width: 100, margin: "auto"}} src="https://www.entourage.ch/storage/2017/01/Visage-homme.jpg" alt="" />
                    <span>SYLVAIN DUPONT</span>
                    <span>RH</span>
                </div>
        </div>}>
        <TreeNode label={<div style={styles}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <img style={{width: 100, margin: "auto"}} src="https://www.entourage.ch/storage/2017/01/Visage-homme.jpg" alt="" />
                    <span>SYLVAIN DUPONT</span>
                    <span>RH</span>
                </div>
        </div>} />
        </TreeNode>
        <TreeNode label={<div style={styles}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <img style={{width: 100, margin: "auto"}} src="https://www.entourage.ch/storage/2017/01/Visage-homme.jpg" alt="" />
                    <span>SYLVAIN DUPONT</span>
                    <span>RH</span>
                </div>
        </div>}>
        <TreeNode label={<div style={styles}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <img style={{width: 100, margin: "auto"}} src="https://www.entourage.ch/storage/2017/01/Visage-homme.jpg" alt="" />
                    <span>SYLVAIN DUPONT</span>
                    <span>RH</span>
                </div>
        </div>}>
            <TreeNode label={<div style={styles}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <img style={{width: 100, margin: "auto"}} src="https://www.entourage.ch/storage/2017/01/Visage-homme.jpg" alt="" />
                    <span>SYLVAIN DUPONT</span>
                    <span>RH</span>
                </div>
        </div>} />
        </TreeNode>
        </TreeNode>
        <TreeNode label={<div style={styles}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <img style={{width: 100, margin: "auto"}} src="https://www.entourage.ch/storage/2017/01/Visage-homme.jpg" alt="" />
                    <span>SYLVAIN DUPONT</span>
                    <span>RH</span>
                </div>
        </div>}>
        <TreeNode label={<div style={styles}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <img style={{width: 100, margin: "auto"}} src="https://www.entourage.ch/storage/2017/01/Visage-homme.jpg" alt="" />
                    <span>SYLVAIN DUPONT</span>
                    <span>RH</span>
                </div>
        </div>} />
        </TreeNode>
        <TreeNode label={<div style={styles}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <img style={{width: 100, margin: "auto"}} src="https://www.entourage.ch/storage/2017/01/Visage-homme.jpg" alt="" />
                    <span>SYLVAIN DUPONT</span>
                    <span>RH</span>
                </div>
        </div>}>
        <TreeNode label={<div style={styles}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <img style={{width: 100, margin: "auto"}} src="https://www.entourage.ch/storage/2017/01/Visage-homme.jpg" alt="" />
                    <span>SYLVAIN DUPONT</span>
                    <span>RH</span>
                </div>
        </div>} />
        </TreeNode>
        <TreeNode label={<div style={styles}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <img style={{width: 100, margin: "auto"}} src="https://www.entourage.ch/storage/2017/01/Visage-homme.jpg" alt="" />
                    <span>SYLVAIN DUPONT</span>
                    <span>RH</span>
                </div>
        </div>}>
        <TreeNode label={<div style={styles}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <img style={{width: 100, margin: "auto"}} src="https://www.entourage.ch/storage/2017/01/Visage-homme.jpg" alt="" />
                    <span>SYLVAIN DUPONT</span>
                    <span>RH</span>
                </div>
        </div>} />
        </TreeNode>
    </Tree>
    </div>
  )
}

export default Organigramme