import React from 'react'

const Profil = ({history}) => {
  return (
    <div className="profil" style={styles.profil}>
        <h1>MAGALIE DUPONT</h1>
        <img style={{width: 400, height: 350, margin: "auto", borderRadius: "50%"}} src="https://www.mariderm.com/wp-content/uploads/2018/12/visage-tonus-after.jpg" alt="" />
        <h3>Fonction: Directrice des Ressources Humaines</h3>
        <h3>Emplacement: CNES TOULOUSE</h3>
        <h3>MAIL: magalie.dupont@cnesmail.com</h3>
        <h3>TEL: 06.05.06.05.06</h3>
    </div>
  )
}

export default Profil

const styles = {
    profil: {
        width: "60%",
        margin: 'auto',
        minHeight: '30vh',
        border: "solid 1px black",
        marginTop: 50
    }
}