import React from 'react'
import { useNavigate } from 'react-router-dom';

const Poc = () => {
    const navigate = useNavigate()

  return (
    <div className='content-poc'>
        <h1 className='title-poc'>Thermique</h1>
        <div className='bloc-poc'>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25), backgroundColor: "#8c7ae6"}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25)}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25), backgroundColor: "#8c7ae6"}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25)}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25)}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25)}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25), backgroundColor: "#8c7ae6"}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25), backgroundColor: "#8c7ae6"}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25), backgroundColor: "#8c7ae6"}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25)}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25)}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25)}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25)}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25)}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25)}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25)}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25)}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25)}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25), backgroundColor: "#8c7ae6"}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25)}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25)}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25)}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25)}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25)}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25)}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25)}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25)}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25)}}></div>
            <div className="circle" onClick={() => navigate("/profil")} title="Magalie Dupont" style={{margin: ~~(Math.random() * 25)}}></div>
        </div>
    </div>
  )
}

export default Poc
