import React, {useEffect, useState} from 'react'
import Globe from 'react-globe.gl';
import { useNavigate } from 'react-router-dom';

//https://github.com/vasturiano/react-globe.gl/blob/master/example/random-arcs/index.html

const GlobeArc = () => {

    const [arcsData, setGData] = useState([])
    const navigate = useNavigate()
    useEffect(() => {
        const arcsData = [...Array(50).keys()].map(() => ({
            startLat: (Math.random() - 0.5) * 180,
            startLng: (Math.random() - 0.5) * 360,
            endLat: (Math.random() - 0.5) * 180,
            endLng: (Math.random() - 0.5) * 360,
            name: "Magalie Dupont",
            color: [['red', 'white', 'blue', 'green', 'yellow'][Math.round(Math.random() * 3)], ['red', 'white', 'blue', 'green'][Math.round(Math.random() * 3)]]
          }));
          setGData(arcsData)
    }, [])
   
  return (
    <>
        <Globe
            globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
            arcsData={arcsData}
            arcColor={'color'}
            arcDashLength={1000}
            width={1800}
            onArcClick={() => navigate('/profil')}
        />
    </>
  )
}

export default GlobeArc