
import './App.css';
import Home from './pages/Home';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
import GlobeArc from './pages/GlobeArc';
import Organigramme from './pages/Organigramme';
import Interest from './pages/Interest';
import Profil from './pages/Profil';
import Poc from './pages/Poc';
 
function App() {
  return (
    <div className="App">
       <Router>
      <div>
        <div style={styles.nav}>
            <p style={styles.link}>
              <Link style={styles.link} to="/">Globe points</Link>
            </p>
            <p style={styles.link}>
              <Link style={styles.link} to="/arc">Globle arc</Link>
            </p>
            <p style={styles.link}>
              <Link style={styles.link} to="/hierarchie">Hierarchie</Link>
            </p>
            <p style={styles.link}>
              <Link style={styles.link} to="/poc">Interet</Link>
            </p>
        </div>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes >
          <Route path="/" element={<Home></Home>}>
          </Route>
          <Route path="/arc" element={<GlobeArc></GlobeArc>}>
          </Route>
          <Route path="/hierarchie" element={<Organigramme></Organigramme>}>
          </Route>
          <Route path="/interest" element={<Interest></Interest>}>
          </Route>
          <Route path="/profil" element={<Profil></Profil>}>
          </Route>
          <Route path="/poc" element={<Poc></Poc>}>
          </Route>
        </Routes >
      </div>
    </Router>
    </div>
  );
}

export default App;

const styles = {
  nav: {
    height: "10vh",
    width: "100%",
    backgroundColor: "#353b48",
    display: "flex",
    alignItem: "center",
    justifyContent: "center"
},
link: {
    color: "#f5f6fa",
    fontSize: 25,
    margin: 20,
},
}
